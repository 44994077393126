import { AppProps } from 'next/app';
import { ReactElement } from 'react';
import { GlobalProviders } from '@/components/providers/GlobalProviders';
import { useGlobalStyles } from '@/stitches.config';
import '../styles/global.css';
import '../styles/tailwind.css';
import '@radix-ui/themes/styles.css';
import '../styles/radix-ui-theme-config.css';
import { dayjsInitialization } from '@/utils/dates';
import { useMonitoringInitialization } from '@/utils/monitoring';
import { Theme } from '@radix-ui/themes';

dayjsInitialization();

const GlobalInitialization = ({ children }: { children: ReactElement }) => {
  useMonitoringInitialization();
  return children;
};

function App({ Component, pageProps }: AppProps) {
  useGlobalStyles();
  return (
    <GlobalProviders>
      <GlobalInitialization>
        <Theme>
          <Component {...pageProps} />
        </Theme>
      </GlobalInitialization>
    </GlobalProviders>
  );
}

export default App;