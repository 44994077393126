import { usePathname, useSearchParams } from 'next/navigation';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { createContext, useContext, useEffect, useMemo } from 'react';
import { useCurrentUser } from "@/api/Me";

interface AnalyticsContextType {
  track: (eventName: string, properties?: Record<string, any>) => void;
}

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

const isProduction = process.env.NODE_ENV === 'production';

// PostHog initialization function
const initPostHog = (userId?: string) => {
  if (!isProduction) {
    console.log('Analytics in debug mode - no data being sent to PostHog');
    return;
  }

  if (process.env.NEXT_PUBLIC_POSTHOG_API_KEY) {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,
      capture_pageview: true,
      capture_pageleave: true,
      disable_session_recording: true,
      persistence: 'localStorage',
      bootstrap: {
        distinctID: userId,
      },
    });
  }
};

// Page view tracking component
const PageViewTracker = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const {data: currentUser} = useCurrentUser();

  useEffect(() => {
    if (!isProduction || !pathname) return;

    let url = window.origin + pathname;
    if (searchParams?.toString()) {
      url = url + `?${searchParams.toString()}`;
    }

    const properties: Record<string, any> = {
      $current_url: url,
    };

    if (currentUser) {
      properties.user_id = currentUser.id;
      properties.user_email = currentUser.email;
      properties.organization = currentUser.organization_name;
    }

    posthog.capture('$pageview', properties);
  }, [pathname, searchParams, currentUser]);

  return null;
};

// User identification component
const UserIdentifier = () => {
  const {data: currentUser, isLoading, error} = useCurrentUser();

  useEffect(() => {
    if (!isProduction || isLoading || error || !currentUser) return;

    posthog.identify(currentUser.id, {
      email: currentUser.email,
      name: currentUser.name,
      organization: currentUser.organization_name,
    });

    posthog.register({
      user_id: currentUser.id,
      user_email: currentUser.email,
      organization: currentUser.organization_name,
    });
  }, [currentUser, error, isLoading]);

  return null;
};

// Analytics Provider component
export function AnalyticsProvider({children}: { children: React.ReactNode }) {
  const {data: currentUser} = useCurrentUser();

  useEffect(() => {
    initPostHog(currentUser?.id);
  }, [currentUser?.id]);

  const analyticsContextValue = useMemo(() => ({
    track: (eventName: string, properties: Record<string, any> = {}) => {
      if (!isProduction) {
        console.log('Analytics Event (Debug):', {
          event: eventName,
          properties: {
            ...properties,
            user_id: currentUser?.id,
            user_email: currentUser?.email,
            organization_name: currentUser?.organization_name,
            organization_id: currentUser?.organization_id,
            user_role: currentUser?.organization_user_role,
          },
        });
        return;
      }

      const enrichedProperties = {
        ...properties,
        user_id: currentUser?.id,
        user_email: currentUser?.email,
        organization_name: currentUser?.organization_name,
        organization_id: currentUser?.organization_id,
        user_role: currentUser?.organization_user_role,
      };
      posthog.capture(eventName, enrichedProperties);
    },
  }), [currentUser]);

  return (
    <PostHogProvider client={posthog}>
      <AnalyticsContext.Provider value={analyticsContextValue}>
        {isProduction && (
          <>
            <PageViewTracker/>
            <UserIdentifier/>
          </>
        )}
        {children}
      </AnalyticsContext.Provider>
    </PostHogProvider>
  );
}

// Hook for using analytics
export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};