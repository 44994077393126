import { createStyleObject } from '@capsizecss/core';
import fontMetrics from '@capsizecss/metrics/inter';
import { ComponentProps } from 'react';
import { styled, theme } from '../../stitches.config';

const parsePx = (value: string) => parseInt(value.replace('px', ''), 10);

const createFontSizeVariant = (size: keyof typeof theme.fontSizes) => {
  const fontSize = parsePx(theme.fontSizes[size].value);
  const leading = parsePx(theme.lineHeights[size].value);

  const styles = createStyleObject({
    fontSize,
    leading,
    fontMetrics,
  });

  return {
    fontSize: styles.fontSize,
    lineHeight: styles.lineHeight,
    '&::before': {
      ...styles['::before'],
      marginBottom: `${parseFloat(styles['::before'].marginBottom)}px`,
    },
    '&::after': {
      ...styles['::after'],
      marginTop: `${parseFloat(styles['::after'].marginTop)}px`,
    },
  };
};

export const Text = styled('p', {
  margin: 0,
  fontFamily: 'var(--font-inter)',
  defaultVariants: {
    color: 'primary',
    size: 'medium',
    weight: 'normal',
    align: 'left',
    verticalAlign: 'top',
    textTransform: 'none',
  },
  'span&': {
    display: 'inline-block',
  },
  variants: {
    color: {
      inherit: {
        color: 'inherit',
      },
      primary: {
        color: '$silvermist-950',
      },
      secondary: {
        color: '$silvermist-700',
      },
      tertiary: {
        color: '$silvermist-500',
      },
      disabled: {
        color: '$silvermist-300',
      },
      accent: {
        color: '$lupine-500',
      },
      success: {
        color: '$pineglade-700',
      },
      error: {
        color: '$bloodmoon-700',
      },
      warning: {
        color: '$sunbark-700',
      },
      'primary-inverse': {
        color: '$white',
      },
      marketing: {
        color: '$lupine-950',
      },
      link: {
        color: '$lupine-500',
        '&:hover': {
          color: '$lupine-700',
        },
      }
    },
    weight: {
      normal: {
        fontWeight: '$normal',
      },
      medium: {
        fontWeight: '$medium',
      },
      semibold: {
        fontWeight: '$semibold',
      },
    },
    size: {
      xsmall: {
        ...createFontSizeVariant('xsmall'),
      },
      small: {
        ...createFontSizeVariant('small'),
      },
      medium: {
        ...createFontSizeVariant('medium'),
      },
      large: {
        ...createFontSizeVariant('large'),
        letterSpacing: '$large',
      },
      xlarge: {
        ...createFontSizeVariant('xlarge'),
        letterSpacing: '$xlarge',
      },
      xxlarge: {
        ...createFontSizeVariant('xxlarge'),
        letterSpacing: '$xxlarge',
      },
      xxxlarge: {
        ...createFontSizeVariant('xxxlarge'),
        letterSpacing: '$xxxlarge',
      },
    },
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
    verticalAlign: {
      middle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      top: {
        display: 'flex',
        alignItems: 'flex-start',
      },
      bottom: {
        display: 'flex',
        alignItems: 'flex-end',
      },
    },
    textDecoration: {
      underline: {
        borderBottom: '1px solid currentColor',
      },
      none: {}, // For no underline
    },
    textTransform: {
      none: {},
      uppercase: {
        textTransform: 'uppercase',
      },
    },
    textOverflow: {
      ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }
    },
    overflowWrap: {
      breakWord: {
        overflowWrap: 'break-word',
      },
    },
    whiteSpace: {
      noWrap: {
        whiteSpace: 'nowrap',
      },
    },
    maxLines: {
      1: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 1,
        textOverflow: 'ellipsis',
      },
      2: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        textOverflow: 'ellipsis',
      },
      3: {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        textOverflow: 'ellipsis',
      },
    },
  },
});

export type TextColor = ComponentProps<typeof Text>['color'];
